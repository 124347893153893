<template>
  <main class="content-page">
    <div class="container">
      <div class="row">
        <div class="content">
          <div class="h3-text darkgrey--text vacancies-title">
            В нашу команду <br />
            требуются:
          </div>
          <div class="vacancies-list">
            <div
              class="vacancies-list-item"
              v-for="vacancy in vacancies"
              :key="vacancy.alias"
            >
              <div class="vacancy-image">
                <img
                  :src="`/images/dist/${vacancy.alias}.svg`"
                  :alt="vacancy.stack"
                />
              </div>
              <div class="vacancy-detail">
                <div class="name">{{ vacancy.level }} {{ vacancy.name }}</div>
                <div class="vacancy-stack">
                  ({{ vacancy.stack }})
                  <span class="vacancy-label">Full Time</span>
                </div>
              </div>
              <div class="vacancy-action">
                <router-link
                  :to="{ name: 'Vacancy', params: { slug: vacancy.alias } }"
                  class="btn btn-gradient"
                  >Подробнее</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <ResumeForm />
      </div>
    </div>
  </main>
</template>

<script>
import ResumeForm from "@/components/ResumeForm";
export default {
  name: "Vacancies",
  data() {
    return {
      vacancies: []
    };
  },
  components: { ResumeForm },
  methods: {
    async getVacancies() {
      try {
        const response = await fetch("/api/vacancies.json");
        const { vacancies } = await response.json();
        this.vacancies = Object.values(vacancies).filter(
          vacancy => vacancy.active
        );
      } catch (e) {
        console.log(e);
      }
    }
  },
  async mounted() {
    await this.getVacancies();
  }
};
</script>

<style scoped></style>
